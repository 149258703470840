.coffeproductcontent {
    position: relative;
    padding: 90px 0;
}
#coffecproductstop {
    width: 100%;
    position: absolute;
    top: -60px;
    height: 150px;
}
.coffee-btns {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}
.coffeproducts {
    display: flex;
    justify-content: center;
    align-items: center;
}
.coffe-products-para {
    text-align: center;
    margin: 40px 0 !important;
}
.coffe-products-para p {
    font-family: "nexa light";
    font-size: 22px;
    line-height: 42px;
}
.coffe-products-para strong {
    font-family: "nexa bold";
    text-transform: uppercase;
}
.coffe-products-para h1 {
    margin-top: 20px;
    font-family: "nexa bold";
    font-size: 22px;
}
@media (max-width: 568px) {
    #coffecproductstop {
        height: 80px;
    }
    .coffeproductcontent {
        padding: 20px 0 90px;
    }
}