.filter-btn {
    color: #fff;
    width: 200px;
    font-size: 1rem;
    padding: 15px 10px;
    text-transform: uppercase;
    font-family: "nexa bold";
    border-radius: 10px;
    margin: 5px;
    cursor: pointer;
    border: none;
    outline: none;
}
.filter-btn:hover {
    filter: brightness(0.9);
}