.prodcut-about {
    margin-top: 80px;
    text-align: center;
    position: relative
}

.product-about-content {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.product-about-content strong {
    font-family: "nexa bold";
}
.product-about-content h1 img {
    width: 150px;
}
.product-about-content p {
    font-family: "nexa light"; 
    color: #000;
    font-size: 26px;
}
.product-about-content span {
    text-transform: uppercase;
}
.product-about-content img {
    height: 400px;
    object-fit: contain;
    margin-top: 40px;
}

#product-bottom {
    width: 100%;
    position: absolute;
    transform: rotate(180deg);
    left: 0;
    right: 0;
    bottom: -64px;
    height: 66px;
}


@media (max-width: 1200px) {
    .product-about-content h1 {
        font-size: 46px;
    }
}
@media (max-width: 992px) {
    .product-about-content h1 {
        font-size: 36px;
    }
    .product-about-content h1 img {
        width: 115px;
    }
    .product-about-content p {
        font-size: 22px;
    }
}
@media (max-width: 768px) {
    .product-about-content h1 {
        font-size: 28px;
        line-height: 22px;
    }
    .product-about-content img {
        width: 250px;
    }
    .product-about-content h1 img {
        width: 100px;
    }
}
@media (max-width: 576px) {
    .product-about-content h1 {
        font-size: 18px;
    }
    .product-about-content img {
        width: 170px;
        margin-top: unset;
    }
    .product-about-content h1 img {
        width: 70px;
    }
    .product-about-content p {
        font-size: 16px;
    }
} 