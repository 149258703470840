.quality-about {
    height: 85vh;
    background-size: cover !important;
    background-attachment: fixed !important;
    display: flex;
    justify-content: center;
    align-items: center;
}

.quality-about p {
    width: 100%;
    color: #fff;
    font-family: "melto";
    font-size: 5rem;
    line-height: 90px;
    letter-spacing: 2px;
    text-align: center;
}

@media (max-width: 768px) {
    .quality-about p {
        font-size: 60px;
    }
}
@media (max-width: 576px) {
    .quality-about p {
        font-size: 50px;
        line-height: 55px;
    }
}