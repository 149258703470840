.products {
    margin-top: 120px;
    margin-bottom: 120px;
}

.products-section-title {
    text-align: center;
    color: #1E1E1E;
    font-family: "nissan";
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 5.8px;
    text-transform: uppercase;
}

.products-container {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}