.ingredients {
    position: relative;
}

#ingredients-top {
    position: absolute;
    top: -30px;
    left: 0;
    right: 0;
    width: 100%;
    height: 100px;
}

.ingredients-content {
    padding-top: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}


.ingredients-content h1 img {
    width: 110px;
}
.ingredients-content p {
    color: #000;
    font-family: "nexa light";
    line-height: 38px;
    font-size: 22px;
    text-align: center;
}
.ingredients-content p strong {
    font-family: "nexa bold";
}

.ingredients-slider {
    margin-top: 60px !important;
    max-width: 100%;
}

.ingredients-slider p {
    text-align: start;
    margin-bottom: 30px;
}

.ingredients-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.ingredients-slide h1 {
    font-size: 38px;
    font-family: "melto";
    letter-spacing: 3px;
    width: 80%;
    text-align: center;
    margin: 0;
}
.ingredients-slide img {
    width: 280px;
    height: 280px;
    margin-top: 25px;
    object-fit: contain;
}
@media (max-width: 1200px) {
    .ingredients-content p {
        font-size: 18px;
        line-height: 35px;
    }
    .ingredients-slide h1 {
        font-size: 34px;
    }
}
@media (max-width: 992px) {
    .ingredients-content p {
        font-size: 20px;
    }
}
@media (max-width: 768px) {
    .ingredients-content h1 {
        font-size: 22px;
    }
}
@media (max-width: 567px) {
    .ingredients-slider ul {
        list-style: none !important;
        padding-left: unset !important;
    }
    .ingredients-content h1 {
        font-size: 16px;
    }
    .ingredients-slide h1 {
        font-size: 22px;
    }
    .ingredients-content h1 img {
        width: 80px;
    }
    .ingredients-slide img {
        width: 190px;
        height: unset;
    }
    .ingredients-content p {
        font-size: 16px;
        line-height: 30px;
    }
    .ingredients-slider p {
        text-align: center;
    }
}