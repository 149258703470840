.coffe-product {
    height: 500px;
    margin-top: 20px;
    border-radius: 10px;
    padding: 24px 20px;
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    flex-direction: column;
}
.coffe-product h1 {
    color: #fff;
    font-family: "melto";
    font-weight: 700;
    font-size: 28px;
    text-transform: uppercase;
    text-align: center;
}
.coffe-product-img {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.coffe-product-img img {
    max-width: 230px;
    transition: .3s ease-in-out;
    cursor: pointer;
    object-fit: contain;
    height: 300px;
}
.coffe-product-img img:hover {
    animation: shake;
    animation-duration: .75s;
    animation-timing-function: linear;
    animation-iteration-count: 1;
}