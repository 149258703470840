.about-categories {
    height: 90vh;
    margin-bottom: -110px;
}

@media (max-width: 768px) {
    .about-categories {
        height: unset;
    }
}
