.hero-section{
    width: 100%;
    height: 94vh;
    background: linear-gradient(rgba(0,0,0,0.3),rgba(0,0,0,0.4)) no-repeat;
    position: relative;
}
.hero-section img {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    object-fit: cover;
    z-index: -1;
}
.hero-section h1 {
    animation-name: backInUp;
    width: 100%;
    position: absolute;
    text-align: center;
    color: #fff;
    font-family: "melto";
    text-transform: uppercase;
    font-size: 6rem;
    top: 50%;
    transform: translate(-50%, -50%);
}

@media (max-width: 576px) {
    .hero-section h1 {
        font-size: 60px;
    }
}