.float-btn {
    position: fixed;
    bottom: 20px;
    right: 10px;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    border: 1px solid var(--main-color);
    background: #fff;
    color: var(--main-color);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 22px;
    z-index: 100;
    cursor: pointer;
    transition: .4s ease-in-out;
}
.float-btn:hover {
    transform: scale(1.1);
}

.float-btn-img {
    width: 25px !important;
}