.contact {
    position: relative;
    padding-top: 70px;
}
#contact-up {
    width: 100%;
    position: absolute;
    top: -60px;
    height: 150px;
}

.contact-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.contact-btns {
    width: 100%;
    margin-bottom: 100px;
}
.contact-content .contact-title {
    margin: 20px 0;
    font-family: "melto";
    color: #000;
    letter-spacing: 2px;
    font-size: 35px;
    text-align: center;
    text-transform: uppercase;
}

.contact-btn {
    margin-top: 40px;
    box-shadow: 1px 4px 7px 0px rgba(0,0,0,.25);
    border-radius: 10px;
    min-height: 200px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    transition: .1s ease-in-out;
}
.contact-btn-title {
    margin: 0;
    text-align: center;
    text-transform: uppercase;
    font-family: "melto";
    color: #000;
    font-size: 28px;
}
.contact-btn-link {
    text-align: center;
    color: #10539e;
    font-size: 20px;
}
.contact-btn:hover {
    background: var(--main-color);
    color: #fff !important;
}  
.contact-btn:hover .contact-btn-title {
    color: #fff !important;
}
.contact-btn:hover .contact-btn-link {
    color: #fff !important;
}


.contact-icons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-bottom: 100px;
}
.contact-icon {
    background: #10539e;
    border-radius: 50%;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: .3s ease;
    cursor: pointer;
}
.contact-icon:hover {
    margin-bottom: -25px;
}
.contact-icons img {
    width: 50px;
    height: 50px;
}


@media (max-width: 576px) {
    .contact-content .contact-title {
        font-size: 30px
    }
    .contact-icons img {
        width: 40px;
        height: 40px;
    }
    .contact-icon {
        padding: 15px;
    }
}