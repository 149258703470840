.category {
    clip-path: circle();
    position: relative;
    height: 202px;
    cursor: pointer;
}
.category-content {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.6));
}
.category-content .category-title {
    color: #fff;
    text-align: center;
    font-family: "Inter";
    font-size: 26px;
    font-style: normal;
    font-weight: 200;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin: 5px;
}

.category video {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
}




@media (max-width: 768px) {
    .category-content {
        margin-top: 20px;
    }
    
}