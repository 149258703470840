.navpages {
    padding: 25px 0 !important;
    position: fixed !important;
    left: 0;
    right: 0;
    top: 0;
    z-index: 100;
    background-size: cover !important;
}


.navbar-toggler {
    border: 3px solid #fff !important;
    transition: .3s ease-in-out !important;
}
.navbar-toggler:hover {
    transform: scale(1.1);
}
.navbar-toggler:focus {
    box-shadow: unset !important;
}

.navbar-toggler-icon {
    background: url("../../assets/images/vectors/icons8-menu-bar.svg") !important;
}

.navbar-container {
    position: relative;
}

.nav-language button {
    text-transform: uppercase !important;
    background: none !important;
    border: none !important;
    font-size: 16px !important;
    font-family: "nissan-bold" !important;
}

.navlogo {
    position: absolute;
    top: -20px;
    left: 50%;
    transform: translateX(-50%);
}
.navlogo img {
    margin-left: -50px;
    width: 220px !important;
}

.nav-content-container {
    width: 100%;
    height: unset;
}

.nav-content {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.nav-link {
    color: #fff !important;
}

.nav-drop, .navbar-link {
    position: relative;
    color: #FFF !important;
    font-family: "nissan-bold";
    font-size: 16px !important;
    font-style: normal;
    line-height: normal;
    text-transform: uppercase;
    transition: .3s ease-in;
}
.nav-drop, .navbar-link:not(:last-child) {
    margin-right: 14px;
}
.navbar-link::after {
    position: absolute;
    content: '';
    width: 1px;
    height: 15px;
    background: #C6C6C6;
    right: -10px;
    top: 50%;
    transform: translateY(-50%);
}
/* .nav-drop::before {
    position: absolute;
    content: '';
    width: 1px;
    height: 15px;
    background: #C6C6C6;
    right: -10px;
    top: 50%;
    transform: translateY(-50%);
} */
.navbar-link:last-child::after {
    display: none;
}
.nav-link:hover {
    color: #ffef00 !important;
}
.nav-drop:hover {
    color: #ffef00 !important;
}

.nav-content-img {
    display: none;
}
.nav-content-btn {
    display: none;
}

.dropdown-menu[data-bs-popper] {
    right: -20px !important;
    left: unset !important;
}
.close-button {
    background: none;
    border: none;
	height: 50px;
    width: 50px;
    position: relative;
	box-sizing: border-box;
	line-height: 50px;
	display: inline-block;

	&:before, &:after {
		transform: rotate(-45deg);
		content: '';
		position: absolute;
		top: 50%;
		left: 50%;
		margin-top: calc(-10px/2);
		margin-left: calc(-44px/2);
		display: block;
		height: 10px;
		width: 44px;
		background-color: #fff;
		transition: all 0.2s ease-out;
	}

	&:after {
		transform: rotate(45deg);
	}

	&:hover {

		&:before, &:after {
			transform: rotate(0deg);
		}
	}
}

@media (max-width: 1440px) {
    .nav-link, .nav-language button {
        font-size: 14px !important;
    }
    .navlogo img {
        width: 200px;
        margin-left: -50px;
    }
    
}

@media (max-width: 1200px) {
    .nav-link, .nav-language button {
        font-size: 13px !important;
    }
    .navlogo img {
        width: 170px !important;
        margin-left: -80px;
    }
    
}



@media (max-width: 992px) {
    .navpages {
        padding: unset !important;
    }
    .navbar-container {
        margin-bottom: 35px;
        position: unset;
    }
    .navlogo {
        position: relative;
        left: unset;
        top: unset;
        transform: unset;
    }
    .navlogo img {
        margin-left: unset !important;
    }
    .nav-content-container {
        position: absolute;
        width: 100%;
        background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.6));
        z-index: 10;
        right: 0;
        top: 0;
        transition: .5s ease-in-out;
    }
    .nav-content {
        z-index: 20;
        overflow: hidden;
        padding: 20px 15px;
        position: absolute;
        height: 100vh;
        background: #10539E;
        width: 390px;
        top: 0;
        right: -200%;
        display: flex;
        justify-content: start;
        align-items: end;
        flex-direction: column;
        transition: .5s ease-in-out;
    }
    .nav-content-btn {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 20px;
    }
    .nav-link, .nav-drop, .nav-language button {
        margin-right: unset !important;
        font-size: 25px !important;
        margin-bottom: 20px;
        transition: .4s ease-in-out !important;
    }

    .nav-language .dropdown-menu {
        position: absolute !important;
    }
    .nav-language .dropdown-menu[data-bs-popper] {
        top: 70% !important;
        right: 0 !important;
        left: unset !important;
    }
    .nav-drop {
        margin-bottom: 0 !important;
    }
    .navbar-link::after {
        display: none;
    }
    .navbar-link:hover {
        margin-right: 10px !important;
    }
    .nav-drop::before {
        display: none;
    }
    .navbar-nav {
        align-items: flex-end;
    }
    .nav-content-img {
        display: block;
        position: absolute;
        width: 100%;
        bottom: 350px;
    }
    .nav-content-img img {
        width: 173px;
        position: absolute;
    }
}