.agent {
    margin-top: 40px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    font-family: "cairo";
    padding: 30px 20px;
    box-shadow: 1px 4px 7px 0px rgba(0,0,0,.25);
    border-radius: 10px;
    height: 300px;
    background: #fff;
    transition: .3s ease-in-out;
}
.agent:hover {
    background: var(--main-color);
}

.agent-title {
    color: var(--main-color);
    font-size: 18px;
    font-family: "cairo bold" !important;
    margin-bottom: 20px;
    transition: .3s ease-in-out;
}
.agent:hover .agent-title {
    color: #fff !important;
    margin-right: 16px;
}

.agent-desc {
    font-size: 14px;
    line-height: 30px;
    color: #000;
    transition: .3s ease-in-out;
}
.agent:hover .agent-desc  {
    color: #fff !important;
    margin-right: 30px;
}