.footer {
    width: 100%;
    position: relative;
    padding-bottom: 50px;
    background-size: cover !important;
}
#logo2 {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: -30px;
    width: 320px;
    z-index: 1;
}

.footer-content {
    padding-top: 170px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.footer-content h1 {
    color: #FFF;
    text-align: center;
    font-family: "rawafed";
    font-size: 58px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
}

.footer-para {
    width: 40%;
    margin: 20px auto 10px;
}

.footer-para p {
    color: #FFF;
    text-align: center;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 25px; 
}

.footer-content span {
    color: #FFF;
    font-family: "Inter";
    font-size: 13px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}

.footer-img {
    object-fit: contain !important;
    height: 60px !important;
}

@media (max-width: 768px) {
    .footer-para {
        width: unset;
    }
}