.hero {
    width: 100%;
    height: 70vh;
    position: relative;
}
#coffe {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -45px;
    z-index: 2;
    width: 180px;
}
#group320, #group309 {
    position: absolute;
    bottom: -45px;
    width: 50%;
    z-index: 1;
}
#group320 {
    left: 0;
}
#group309 {
    right: 0;
}

.hero-content {
    height: 100%;
}

.hero-first {
    height: 100%;
    position: relative;
   
}

.hero-first img:first-child {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
}

.hero-first-content {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -120px;
    z-index: 3;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.hero-first-content h1 {
    color: #FFF;
    text-align: center;
    font-family: "nissan";
    font-size: 36px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 1px;
    text-transform: uppercase;
}
.hero-first-content h2 {
    color: #FFF;
    text-align: center;
    font-family: "nissan-bold";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 2px;
    text-transform: uppercase;
    margin-bottom: 40px;
}
.hero-first-content img {
    width: 230px;
    transition: .3s ease-in-out;
}
.hero-first-content img:hover {
    animation: shake;
    animation-duration: .75s;
    animation-timing-function: linear;
    animation-iteration-count: 1;

}
.hero-second {
    position: relative;
    padding: 0 !important;
}
.hero-second .splide, .hero-second .splide__track {
    width: 100% !important;
    height: 100%;
}
.hero-second .splide__pagination {
    bottom: 45px !important;
}
.hero-second img {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    top: 0;
    object-fit: cover;
}

.hero-responsive {
    display: none;
}

@media (max-width: 992px) {
    #coffe  {
        width: 150px;
    }
    .hero-first-content h1 {
        font-size: 33px;
    }
    #group320, #group309 {
        bottom: -20px;
    }
    .hero {
        margin-top: 50px;
    }
}
@media (max-width: 768px) {
    .hero-second {
        background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.1));
        position: absolute;
        z-index: -1;
        height: 100%;
        width: 100%;
    }
    #coffe {
        display: none;
    }
    #group320, #group309 {
        bottom: -5px;
    }
    .hero-responsive {
        display: flex;
        justify-content: center;
    }
    .hero-content {
        display: none !important;
    }
    .hero-second .splide__pagination {
        right: 20px;
        display: flex;
        flex-direction: column;
        left: unset;
    }
}