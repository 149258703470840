:root {
  --main-color : #10539e;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  max-width: 100%;
  overflow-x: hidden;
  position: relative;
}

p {
  margin-bottom: 0 !important;
}

a {
  text-decoration: none !important;
}

@font-face {
  font-family: "rawafed";
  src: url("../src/assets/Fonts/Rawafed\ Zainab.otf");
}
@font-face {
  font-family: "nissan";
  src: url("../src/assets/Fonts/Nissan\ Brand\ W01\ Light.ttf");
}
@font-face {
  font-family: "nissan-bold";
  src: url("../src/assets/Fonts/Nissan\ Brand\ W06\ Bold.ttf");
}
@font-face {
  font-family: "melto";
  src: url("../src/assets/Fonts/MeltowSan200-Regular.ttf");
}
@font-face {
  font-family: "nexa light";
  src: url("../src/assets/Fonts/Nexa-ExtraLight.ttf");
}
@font-face {
  font-family: "nexa bold";
  src: url("../src/assets/Fonts/Nexa-Heavy.ttf");
}
@font-face {
  font-family: "cairo bold";
  src: url("../src/assets/Fonts/Cairo-Bold.ttf");
}
@font-face {
  font-family: "cairo";
  src: url("../src/assets/Fonts/Cairo-Regular.ttf");
}

.no-result {
    color: #10539e;
    text-align: center;
    font-family: "nexa bold";
    font-size: 40px;
    text-transform: capitalize;
    letter-spacing: 2px;
}

@keyframes shake {
  10% {
      transform: translateX(3px) rotate(2deg);
  }
  20% {
      transform: translateX(-3px) rotate(-2deg);
  }
  30% {
      transform: translateX(3px) rotate(2deg);
  }
  40% {
      transform: translateX(-3px) rotate(-2deg);
  }
  50% {
      transform: translateX(2px) rotate(1deg);
  }
  60% {
      transform: translateX(-2px) rotate(-1deg);
  }
  70% {
      transform: translateX(2px) rotate(1deg);
  }
  80% {
      transform: translateX(-2px) rotate(-1deg);
  }
  90% {
      transform: translateX(1px) rotate(0);
  }
  100% {
      transform: translateX(-1px) rotate(0);
  }
}

