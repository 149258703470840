.about {
    position: relative;
    width: 100%;
    padding: 70px 0;
    background: var(--main-color);
}
#about-top {
    width: 100%;
    position: absolute;
    top: -60px;
    left: 0;
    right: 0;
    height: 150px;
}
#about-bottom {
    width: 100%;
    position: absolute;
    bottom: -30px;
    left: 0;
    right: 0;
}

.about-content {
    padding-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
}

.about-content h1 {
    color: #fff;
    font-family: "melto";
    font-size: 50px;
    letter-spacing: 2px;
    margin-bottom: 60px;
}

.about-content p {
    color: #fff;
    font-size: 19px;
    font-family: "nexa light";
    letter-spacing: 1px;
    line-height: 30px;
    margin-bottom: 50px;
}

.about-content img {
    width: 80%;
    object-fit: contain;
    margin-top: 60px;
}

.about-content p strong {
    font-family: "nexa bold";
    font-size: 20px;
    text-transform: uppercase;
    letter-spacing: 1px;
}

@media (max-width: 768px) {
    .about-content p {
        font-size: 16px;
    }
    .about-content p span {
        font-size: 17px;
    }
    .about-content h1 {
        font-size: 46px;
    }
    #about-top {
        height: 100px;
    }
}
@media (max-width: 576px) {
    .about-content p {
        font-size: 14px;
    }
    .about-content p span {
        font-size: 15px;
    }
    .about-content h1 {
        font-size: 40px;
    }
    /* .about-content img {
        width: 300px;
    } */
}

@media (max-width: 500px) {
    #about-bottom {
        bottom: -20px;
    }
}