.app {
    width: 100%;
    overflow: hidden;
    /* position: relative; */
}


/**
 * coffee filling loading
**/
.coffee-wrap {
    position: absolute;
    top: 400px;
    width: 100%;
    z-index: 10000;
    background: rgba(0, 0, 0, .05);
  }
  
  .coffee-wrap .coffee {
    width: 260px;
    height: 200px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    padding: 20px;
    border-radius: 5px;
    background: transparent;
  }
  
  .coffee-wrap .coffee p {
    position: absolute;
    bottom: 25px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    font-size: 18px;
    font-family: "nissan";
  }
  
  .coffee-wrap .cup {
    margin: auto;
    position: relative;
    top: 32%;
    width: 50px;
    height: 45px;
    border: 2px solid #ffedd5;
    box-shadow: 0 0 0 5px #352a22;
    border-radius: 10px 10px 50px 55px;
    background-size: 80px;
    background: url('./assets/images/liquid.png');
    background-repeat: repeat-x;
    background-position: 0 130px;
    animation: filling 8s infinite;
  }
  
  .coffee-wrap .cup-handle {
    position: absolute;
    top: 2px;
    right: -20px;
    width: 10px;
    height: 20px;
    border: 4px solid #352a22;
    border-radius: 20px 25px 50px 20px;
  }
  
  .coffee-wrap .cup .steam {
    position: absolute;
    border-radius: 10px 2px;
    width: 5px;
    animation: steaming 2s infinite;
  }
  
  .coffee-wrap .cup .steam:nth-child(1) {
    top: -20px;
    left: 0px;
    height: 15px;
    background: #8e5a3423;
    animation-delay: .2s;
  }
  
  .coffee-wrap .cup .steam:nth-child(2) {
    top: -35px;
    left: 20px;
    height: 20px;
    background: #8e5a3454;
    animation-delay: .6s;
  }
  
  .coffee-wrap .cup .steam:nth-child(3) {
    top: -25px;
    left: 40px;
    height: 20px;
    background: #8e5a3433;
    animation-delay: 1s;
  }
  
  @keyframes filling {
    0%, 100% {
      background-position: 0 -20px;
    }
    50% {
      background-position: 500px -60px;
    }
  }
  
  @keyframes steaming {
    0%, 100% {
      opacity: 0;
    }
    50% {
      opacity: 1;
      filter: blur(.8px);
      transform: translateY(-10px);
    }
  }