.quality {
    width: 90%;
    margin: 180px auto 0;
}
.quality h1 {
    color: #707070;
    text-align: center;
    font-family: "Inter";
    font-size: 21px;
    font-style: normal;
    font-weight: 300;
    margin-bottom: 18px;
    line-height: 30px;
}
.quality strong {
    color: #707070;
    font-family: "Inter";
    font-size: 21px;
    font-style: normal;
    font-weight: 700;
}
.quality p {
    color: #707070;
    text-align: center;
    font-family: "Inter";
    font-size: 21px;
    font-style: normal;
    font-weight: 300;
    line-height: 38px;
}