.otherproducts {
    position: relative;
    padding: 70px 0 100px;
}
#otherproduct-up {
    width: 100%;
    position: absolute;
    top: -60px;
    height: 150px;
}
.otherproduct-content {
    margin-top: 20px;
}
.otherproducts-para {
    font-family: "nexa light";
    font-size: 20px;
    text-align: center;
}
.otherproducts-para strong {
    font-family: "nexa bold";
    text-transform: uppercase;
}

.otherproducts-title img {
    width: 100px;
}

@media (max-width: 768px) {
    .otherproducts-para {
        font-size: 18px;
    }
    .otherproducts-title {
        font-size: 22px;
    }
    .otherproducts-title img {
        width: 90px;
    }
}
@media (max-width: 576px) {
    .otherproducts-para {
        font-size: 16px;
    }
    .otherproducts-title {
        font-size: 18px;
    }
    .otherproducts-title img {
        width: 80px;
    }
    #otherproduct-up {
        height: 80px;
    }
    .otherproducts {
        padding: 1px 0 100px;
    }
}



.otherproduct {
    margin-top: 20px;
    overflow: hidden;
    position: relative;
    height: 600px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    transition: all .3s;
    cursor: pointer;
}
.otherproduct h1 {
    max-width: 80%;
    color: #fff;
    font-family: "melto";
    font-size: 5rem;
    letter-spacing: 1px;
    text-align: center;
}
.otherproduct-img {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
    filter: brightness(0.6);
}
.otherproduct-img img {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    width: 100%;
    object-fit: cover;
    background-position: center;
    transition: .3s;
    height: 100%;
}
.otherproduct:hover {
    filter: brightness(1.4);
}
.otherproduct:hover .otherproduct-img img {
    top: -30px;
    height: calc(100% + 30px);
}

.otherproduct-img .splide, .otherproduct-img .splide__track {
    width: 100% !important;
    height: 100%;
}
.otherproduct-img .splide__pagination {
    bottom: 45px !important;
}

@media (max-width: 992px) {
    .otherproduct {
        height: 450px;
    }
    .otherproduct h1 {
        font-size: 4rem;
    }
}
@media (max-width: 768px) {
    .otherproduct {
        height: 350px;
    }
    .otherproduct h1 {
        font-size: 3rem;
    }
}
@media (max-width: 576px) {
    .otherproduct {
        height: 300px;
    }
}