.hero-details {
    height: 80vh;
    position: relative;
    margin-bottom: 100px;
}
#hero-details-footer {
    position: absolute;
    z-index: 1;
    bottom: -20px;
    width: 100%;
}
.hero-details-img {
    height: 100%;
   
    background-size: cover !important;
}
.hero-details-content {
    position: relative;
    padding-top: 120px;
    height: 100%;
    display: flex;
    justify-content: center;
}
.hero-details-content h1 {
    margin-bottom: 40px;
    color: #fff;
    font-family: "melto";
    text-align: center;
}
.hero-details-product {
    position: absolute;
    bottom: -10px;
    /* width: 220px; */
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
}
.hero-details-product img {
    width: 100%;
    object-fit: contain;
    height: 380px;
    transition: .3s ease-in-out;
}
.hero-details-product img:hover {
    animation: shake;
    animation-duration: .75s;
    animation-timing-function: linear;
    animation-iteration-count: 1;
}

@media (max-width: 992px) {
    .hero-details-content h1 {
        font-size: 24px;
    }
    .hero-details-content  {
        padding-top: 170px;
    }
    .hero-details-product img {
        height: 340px;
    }
}
@media (max-width: 768px) {
    .hero-details-img  {
        display: none;
    }
    /* .hero-details-product {
        bottom: -70px;
    } */
}
@media (max-width: 576px) {
    .hero-details-product {
        width: 280px;
    }
    .hero-details-product img {
        width: 100%;
        object-fit: contain;
    }
}