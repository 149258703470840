.container-form {
    margin-top: 100px;
    padding: 60px 80px;
    background: #ffffff;
    box-shadow: 0px 0px 31px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
}

.form-text {
    margin-top: 40px !important;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    gap: 10px;
}

.form-title {
    font-family: 'cairo bold';
    font-style: normal;
    font-size: 14px;
    color: var(--main-color);
}
.form-title span {
    color: #d93f48;
    margin-right: 5px;
}

.form-input {
    background-color: white;
    border: 1px solid #E6E6E6;
    border-radius: 4px;
    padding: 10px;
    font-family: 'cairo';
    width: 100% !important;
    outline-color: var(--main-color);
}
.form-input::placeholder {
    color: #E6E6E6;
}

.form-btn {
    padding: 10px 20px;
    margin-top: 40px;
    background: linear-gradient( 89.97deg, #10539e, #14143e ), #d9d9d9;
    border-radius: 4px;
    color: #ffffff;
    font-family: "cairo bold";
    font-size: 20px;
    border: none;
    outline: none
}

@media (max-width: 992px) {
    .container-form {
        padding: 60px 80px;
    }
}
@media (max-width: 768px) {
    .container-form {
        padding: 60px 40px;
    }
}
@media (max-width: 576px) {
    .container-form {
        padding: 20px 20px;
    }
}

