.categories {
    height: 100%;
    width: 100%;
    position: relative;
    padding: 80px 0 100px;
    width: 100%;
    margin-top: 120px;
    background-size: cover !important;
}

#quality1 {
    position: absolute;
    bottom: 15px;
    left: 0;
}
#quality2 {
    position: absolute;
    top: 15px;
    right: 0;
}

.categories h1 {
    margin: 20px 0 100px;
    color: #4E3305;
    font-family: "nissan-bold";
    text-align: center;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 5.5px;
    text-transform: uppercase;
}

.categories-content {
    width: 80%;
    margin: 0 auto;
}

.category-container {
    position: relative;
}
.category-container img {
    position: absolute;
    top: -77px;
    left: 50%;
    transform: translate(-50%);
}

@media (max-width: 768px) {
    .category-container img {
        top: 50%;
        left: 78%;
        transform: translate(-50%, -50%);
    }
    .categories-container {
        padding-bottom: 170px;
    }
}
