.product {
    position: relative;
    overflow: hidden;
    margin-top: 40px;
    height: 700px;
    padding: 20px 30px;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    border-radius: 10px;
    background: linear-gradient(rgba(0,0,0,0.1), rgba(0,0,0,0.2)) ,url("../../../../assets/images/pattern.png") no-repeat;
    background-size: cover;
}
.product-before{
    padding: 10px 30px;
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    top: 200%; 
    left: 0;
    background: #000;
    z-index: 1;
    transition: .5s ease-in-out;
    opacity: 0.8;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: #fff;
    font-size: 20px;
}
.product:hover .product-before {
    top: 0;
}

.product-before h2 {
    font-size: 18px !important;
}
.product-before h3 {
    font-size: 16px !important;
}

.product-title {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.product-title h1 {
    margin-top: 40px;
    color: #FFF;
    font-family: "nissan";
    font-size: 30px;
    font-style: normal;
    font-weight: 300;
    letter-spacing: 1.2px;
    text-transform: uppercase;
    text-align: center;
}
.product-title p {
    color: #FFF;
    font-family: "nissan-bold";
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 2.5px;
    text-transform: uppercase;
}
.product-img {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.product-img img {
    width: 270px;
    height: 450px;
    transition: .3s ease-in-out;
    cursor: pointer;
    object-fit: contain;
}
.product-img img:hover {
    animation: shake;
    animation-duration: .75s;
    animation-timing-function: linear;
    animation-iteration-count: 1;
}

@media (max-width: 992px) {
    .product-before h2 {
        font-size: 14px !important;
    }
    .product-before h3 {
        font-size: 14px !important;
    }
}