.agents {
    margin: 150px 0;
}

.agents-title {
    color: var(--main-color);
    font-size: 28px;
    font-family: "nissan-bold";
    text-align: center;
    text-transform: uppercase;
    margin-top: 80px;
}

.branches {
    text-align: center;
    margin-top: 100px !important;
    width: 100%;
    height: 350px;
}
.branches td {
    border: 1px solid #000;
    min-width: 50px;
}
.branches .fill {
    background: rgb(0, 112, 192);
}
.branches #first-column {
   border: none !important;
}


@media (max-width: 992px) {
    .branches td {
        min-width: 40px;
        font-size: 14px;
    }
}

@media (max-width: 576px) {
    .branches td {
        min-width: 20px;
        font-size: 12px;
    }
}