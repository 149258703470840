.details-products {
    margin: 120px 0 20px;
}
.products-title {
    margin: 40px 0 80px;
    font-family: "nexa bold";
    letter-spacing: 3px;
    font-size: 32px;
    text-align: center;
}
.details-productscontainer {
    text-align: center;
}

.details-productscontainer .splide__arrow--next {
    right: -2em;
}
.details-productscontainer .splide__arrow--prev {
    left: -2em;
}

@media (max-width: 576px) {
    .details-productscontainer .splide__arrow--next {
        right: -6px;
    }
    .details-productscontainer .splide__arrow--prev {
        left: -6px;
    }
    .products-title {
        margin: 40px 0 20px;
        font-size: 26px;
        letter-spacing: 1px;
    }
}