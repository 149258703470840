.details-para {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
}
.details-para p {
    font-family: "nexa light";
    font-size: 22px;
}
.details-para strong {
    font-family: "nexa bold";
    font-size: 22px;
}
.details-desc {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
}
.details-desc h1 {
    font-family: "melto";
    font-size: 40px;
    letter-spacing: 1px;
    margin-bottom: 25px;
}
.details-desc p {
    font-family: "nexa light";
    font-size: 22px;
    width: 90%;
}
.details-desc strong {
    font-family: "nexa bold";
    text-transform: uppercase;
}
.details-info {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
}
.details-info h1 {
    font-family: "melto";
    font-size: 40px;
    letter-spacing: 1px;
    margin-bottom: 25px;
}
.details-info-content {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.details-info-content h2 {
    font-family: "nexa light";
    font-size: 22px;
}
.details-info-content h3 {
    font-family: "nexa bold";
    font-size: 22px;
}

@media (max-width: 992px) {
    .details-info h1, .details-desc h1 {
        font-size: 36px;
    }
    .details-info-content h2, .details-info-content h3 {
        font-size: 20px;
    }
    .details-desc p {
        font-size: 20px;
    }
}
@media (max-width: 768px) {
    .details-desc {
        text-align: center;
        align-items: center !important;
    }
    .details-desc p {
        width: unset;
    }
    .details-info {
        margin-top: 40px !important;
        align-items: center;
        text-align: center;
    }
}
@media (max-width: 576px) {
    .details-info h1, .details-desc h1 {
        font-size: 32px;
    }
    .details-info-content h2, .details-info-content h3 {
        font-size: 18px;
    }
    .details-desc p {
        font-size: 18px;
    }
    .details-para p, .details-para span {
        font-size: 20px;
    }
}
